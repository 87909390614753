








































import { Component, Prop, Vue } from "vue-property-decorator";

import { Game } from "@/store/settings";
import { Item } from "@/store/tracker/state";

@Component
export default class BaseCell extends Vue {
  @Prop(String) client!: string;
  @Prop(String) file!: string;

  cls: string | null = this.cell.cls ?? null;
  timer?: number;

  get game(): Game {
    return this.$store.state.settings.game;
  }

  get cell(): Item {
    return this.$store.getters["tracker/cell"](this.client, this.file);
  }

  get number(): number {
    const level = Math.min(this.cell.total, this.cell.level);
    if (!this.cell.numbers) {
      return level;
    }

    return this.cell.numbers[level - 1];
  }

  get dataFile(): string | undefined {
    if (typeof this.cell.data === "string") return this.cell.data;
    return this.cell.data?.[this.cell.level - this.cell.total - 1];
  }

  get secondaryFile(): string {
    return this.$store.getters["tracker/secondary"](this.client, this.file);
  }

  get secondaryNumber(): string {
    return this.$store.getters["tracker/secondaryNumber"](this.client, this.file);
  }

  get corresponding(): string {
    return this.cell.correspondingItem;
  }

  get customDefaults() {
    return this.$store.state.settings.customDefaults[this.file];
  }

  custom(property: string, fallback: any): any {
    return this.customDefaults?.[property] ?? fallback;
  }

  styledIcon(file: string): string {
    const cell = this.$store.getters["tracker/cell"](this.client, file);
    const style = cell.isMinimal
      ? "Minimal"
      : this.$store.state.settings.iconStyles[cell.category]?.value;

    if (style === cell.categoryExclude) {
      return `default/${file}`;
    }

    switch (style) {
      case "Minimal":
        return `minimal/${cell.minimal ?? file}`;
      case "Classic":
        return `legacy/${file}`;
      default:
        return `default/${file}`;
    }
  }

  get itemStyle(): object {
    const width = 100 / (this.$store.state.settings.columns || 5);
    return {
      width: `${width}%`,
      height: 0,
      padding: `${width / 2}% ${this.$store.state.settings.padding || "7.5px"}`,
    };
  }

  dispatch(mutation: string, offset = 1, shift = false): void {
    this.$store.dispatch(`tracker/${mutation}`, {
      client: this.client,
      cell: this.file,
      offset,
      shift,
    });
  }

  handleTouchStart(): void {
    this.timer = window.setTimeout(() => {
      this.triggerSecondary();
      this.timer = undefined;
    }, this.$store.state.settings.longPressDelay);
  }

  handleTouchEnd(e: TouchEvent): void {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
    } else {
      e.preventDefault();
    }
  }

  triggerSecondary(): void {
    if (this.$store.state.drag) {
      this.$emit("remove");
      return;
    }

    this.dispatch("secondary", 1);
  }

  handleMouseDown(event: MouseEvent): void {
    if (this.$store.state.drag) {
      if (event.button === 2) this.$emit("remove");

      return;
    }

    if (this.$store.state.edit) {
      this.$router.push({ name: "EditItem", params: { file: this.file } });
      return;
    }

    const offset = event.ctrlKey ? -1 : 1;

    switch (event.button) {
      case 0:
        // Left Click
        this.dispatch("primary", offset, event.shiftKey);
        break;

      case 2:
        // Right Click
        this.dispatch("secondary", offset);
        break;

      case 1:
        // Middle Click
        this.dispatch("disable");
        break;
    }
  }

  handleWheel(event: WheelEvent): void {
    if (!this.$store.state.settings.scroll) return;

    // Prevent page scroll
    event.preventDefault();

    // Increment/decrement
    const offset = -Math.sign(event.deltaY);
    this.dispatch("primary", offset, event.shiftKey);
  }
}
